// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-all-houses-js": () => import("./../../../src/pages/all-houses.js" /* webpackChunkName: "component---src-pages-all-houses-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-houses-js": () => import("./../../../src/pages/houses.js" /* webpackChunkName: "component---src-pages-houses-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interiors-js": () => import("./../../../src/pages/interiors.js" /* webpackChunkName: "component---src-pages-interiors-js" */),
  "component---src-pages-order-house-construction-js": () => import("./../../../src/pages/order-house-construction.js" /* webpackChunkName: "component---src-pages-order-house-construction-js" */),
  "component---src-templates-city-houses-index-js": () => import("./../../../src/templates/city-houses/index.js" /* webpackChunkName: "component---src-templates-city-houses-index-js" */),
  "component---src-templates-city-infrastructures-index-js": () => import("./../../../src/templates/city-infrastructures/index.js" /* webpackChunkName: "component---src-templates-city-infrastructures-index-js" */),
  "component---src-templates-house-index-js": () => import("./../../../src/templates/house/index.js" /* webpackChunkName: "component---src-templates-house-index-js" */)
}

